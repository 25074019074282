 @import url("https://use.typekit.net/nzg8ysh.css");
/* https://res.cloudinary.com/dgps1radv/image/upload/c_fill,w_200/mto5squ8abpmd02zymev */
$red: #bf272e;
$white: #f2f5f0;
// $white: #fff;
$black: #2c1309;
$borderWidth: 3px;
$paddingY: 0.35em;
$max-site-width: 100%;

$sigFont: gooddog-new, sans-serif;
// $sigFont: courier, monospace;
$sigFontCompressed: gooddog-new, sans-serif;
$deco: gooddog-new, sans-serif;
$script: gooddog-new, sans-serif;

:root {
  // color-scheme: light dark;
}
// $sigFont: expressway, sans-serif;
.ordering-menu {
  // background: $black;
  /* background: none; */
  // color: white;
}

.dads.site-content {
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%235cbd72' fill-opacity='0.4' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
  color: $black;
  a {
    // color: #770092;
    font-weight: 400;
  }
}


.dads {
  font-family: $sigFont;

  .landing-page-wrapper {
    max-width: $max-site-width;
    margin: 0 auto;
  }
  .delivery-note {
    font-family: $sigFontCompressed;
    font-size: 0.6em;
  }
  a.checkout-button.button {
    // background: none !important;
    // color: $red;
    // text-transform: capitalize;
    // display: inline-block;
    // border-bottom: 4px solid $red !important;
    // padding: 0em 0.3em !important;
    // font-size: 1em;
    // font-weight: 900 !important;
  }

  img.nav-bar-logo {
    display: block;
    position: relative;
    height: 44px;
    // margin-top: -0.4em;
    // bottom: -0.6em;
    padding-top: 0;
    padding-left: 0.4em;
    padding-bottom: 0.023em;
  }

  .cart.minimized {
    max-width: $max-site-width;
    margin: 0 auto;
    padding-left: 0;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg width='5' height='5' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%235cbd72' fill-opacity='0.4' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
    // margin-top: -0.5em;
    // background-color: #00d8ce;
    // background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23010202' fill-opacity='0.29' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
    // border: none;
    border-bottom: 1.5px solid black;

  }
  // .hide {
  //   // position: absolute;
  //   width: 100%;
  // }
  .hero-banner {
    // background-color: red;
    // background: url(https://afag.imgix.net/harvest-moon/exterior.jpg?w=1400) no-repeat;
    // background: url(https://afag.imgix.net/lancers-family-restaurant/salads.jpg?w=1200) no-repeat;
    // @media screen and (min-width: 1000px) {
    background-image: url(https://afag.imgix.net/dads-donut-and-bakery/53-9.jpg?w=1200&auto=format);
    // }
    background-size: cover;
    // clip-path: url(#clip);

    min-height: 450px;
    // width: 500%;
    background-position: center 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 1px 1px 1px solid black;

    .icon {
      width: 300px;
      animation-name: spin;
      animation-duration: 500ms;
    }
    @keyframes spin {
      from {
        transform:rotate(0deg) scale(0);
      }
      to {transform:rotate(360deg) scale(1);}
    }

    .text {
      // height: 400px;
      box-sizing: border-box;
      padding: 0.85em 0.3em 0.8em 0em;
      border-radius: 0 0.2em 0.2em 0;
      display: flex;
      // flex-direction: column;
      align-items: center;
      // justify-content: center;
      font-size: 1.5em;
      // padding: 1em 0 0 0.3em;
      // background: #138a81;
      // color: white;
      margin: 0;
      font-family: $sigFont;
      font-weight: 600;
      overflow: hidden;
      @media screen and (max-width: 550px) {
        font-size: 1.1em;
      }
      .hero {
        margin-right: 1em;
        img {
          width: 100%;
          border-radius: 0.5em;
        }
        max-width: 250px;
        @media screen and (max-width: 550px) {
          img {
            border-radius: 0 0.5em 0.5em 0;
          }

        }
      }
      .tag-line {
        width: 250px;
        @media screen and (max-width: 620px) {
          // margin-left: -45%;
        }
        .order-online-button {
          // display: inline-block;
        }
      }
      p {
        // margin: 1em 0;
      }
      .small {
        font-size: 0.5em;
      }
    }
  }
  .food-gallery {
    margin-top: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .photo {
      text-align: center;

      width: 16.666%;
      box-sizing: border-box;
      padding: 0.3em;
      @media screen and (max-width: 500px) {
        width: 33.333%;
      }
      max-width: 200px;
      a {
        // background: $red;
        font-weight: 600;
        font-size: 0.75em;
        color: inherit;

      }
      img {
        border-radius: 0.5em;
        width: 100%;
        display: block;
      }
    }
  }

  .location-info {
    background-color: #f4f4f4;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52'%3E%3Cpath fill='%23e1e1e1' fill-opacity='0.4' d='M0 17.83V0h17.83a3 3 0 0 1-5.66 2H5.9A5 5 0 0 1 2 5.9v6.27a3 3 0 0 1-2 5.66zm0 18.34a3 3 0 0 1 2 5.66v6.27A5 5 0 0 1 5.9 52h6.27a3 3 0 0 1 5.66 0H0V36.17zM36.17 52a3 3 0 0 1 5.66 0h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 0 1 0-5.66V52H36.17zM0 31.93v-9.78a5 5 0 0 1 3.8.72l4.43-4.43a3 3 0 1 1 1.42 1.41L5.2 24.28a5 5 0 0 1 0 5.52l4.44 4.43a3 3 0 1 1-1.42 1.42L3.8 31.2a5 5 0 0 1-3.8.72zm52-14.1a3 3 0 0 1 0-5.66V5.9A5 5 0 0 1 48.1 2h-6.27a3 3 0 0 1-5.66-2H52v17.83zm0 14.1a4.97 4.97 0 0 1-1.72-.72l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1 0-5.52l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43c.53-.35 1.12-.6 1.72-.72v9.78zM22.15 0h9.78a5 5 0 0 1-.72 3.8l4.44 4.43a3 3 0 1 1-1.42 1.42L29.8 5.2a5 5 0 0 1-5.52 0l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1-.72-3.8zm0 52c.13-.6.37-1.19.72-1.72l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43a5 5 0 0 1 5.52 0l4.43-4.43a3 3 0 1 1 1.42 1.41l-4.44 4.43c.36.53.6 1.12.72 1.72h-9.78zm9.75-24a5 5 0 0 1-3.9 3.9v6.27a3 3 0 1 1-2 0V31.9a5 5 0 0 1-3.9-3.9h-6.27a3 3 0 1 1 0-2h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 1 1 2 0v6.27a5 5 0 0 1 3.9 3.9h6.27a3 3 0 1 1 0 2H31.9z'%3E%3C/path%3E%3C/svg%3E");
    padding: 1em 0.5em;
    text-align: center;
    h2 {
      font-family: $deco;
      font-size: 2em;
      margin-top: 0.5em;
    }
    p {
      margin: 0;
    }
  }
  .news {
    max-width: 850px;
    margin: 0 auto;
    margin-top: 1.8em;
    padding: 0 0.5em;
    display: flex;
    // flex-wrap: wrap;
    // align-items: center;
    justify-content: center;
    .hero {
      width: 70px;
      margin-right: 0.5em;
      img {
        width: 100%;
        border-radius: 0.3em;
      }
    }
    .full-menu {
      margin-top: 1.5em;

      text-align: right;
      a {
        margin-left: 0.5em;
        background: black;
        color: white;
        padding: 0.2em 0.3em;
        border-radius: 0.2em;
        text-decoration: none;
      }
    }
    h2, h3 {
      font-weight: 900;
      font-size: 1.2em;
      // text-transform: uppercase;
      // margin-bottom: 1em;
      font-family: $deco;
    }
    a {
      // text-transform: uppercase;
      // font-family: $deco;
    }
  }
  .features {
    padding: 2em 0;
  }
  .feature-section.visit-us {
    img {
      // width: in;
      height: auto !important;
      object-fit: none;
    }
  }
  .feature-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1em 0em;
    .photo {
      width: 40%;
      img {
        width: 100%;
        height: 350px;
        object-fit: cover;
        border-radius: 0.2em;
      }

    }
    .content {
      box-sizing: border-box;
      width: 40%;
      padding-left: 1.5em;
    }
    @media screen and (max-width: 600px) {
      display: block;
      .photo, .content {
        width: 100%;
        padding: 1em 0.5em;
      }
      .photo {
        padding: 1em 0;
      }
    }
    h2, h3 {
      font-weight: 900;
      font-size: 1.2em;
      // text-transform: uppercase;
      margin-bottom: 1em;
      font-family: $deco;
    }
    .text {
      font-size: 0.75em;
      line-height: 1.5em;
      font-weight: 400;
    }
    a.link {
      font-weight: 600;
      // text-decoration: none;
    }

  }
  .order-benefits-wrapper {
    // min-height: 200px;
    // background: url(https://afag.imgix.net/hayats-kitchen/hero-1.jpg?w=1200) no-repeat;
    background-size: cover;
    background-position: center 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      color: white;
      background: black;
      padding: 0.2em 0.4em;
    }
  }
  .landing-page .text {
    color: white;
    text-shadow: 1.5px 1.5px 1.5px black;
    padding: 0.5em 0.8em;
    max-width: 360px;
  }

  a.checkout-button.button {
    background: $red;

    font-weight: 600;
    box-shadow: none;
    border: none;

  }

  /* .category__header {
  font-family: cursive;

} */
.category-name .text {
  color: black;

  font-family: $deco;
  font-weight: 600;
  // text-transform: uppercase;
  font-size: 1.5em;
  // line-height: 0.7em;
  // padding: 0.3em 0.3em 0.2em 0.3em;
  border-radius: 3px;
  background: none;
  position: relative;


}
.menu-category-list-view {
  text-transform: lowercase;
  .food-name {
    font-weight: 600 !important;
    font-family: $deco;
    // font-size: 1.2em;
  }
  .price {
    font-family: $deco;
  }
  .content {
    // font-size: 1.3em;
    // width: 70%;
  }
}

// WINE products special display
.category.wine {
  .menu-category-list-view {

  }
}

.top-banner {
  min-height: 40vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  // text-shadow: 1px 1px 0 $red;
  .text {
    display: inline-block;
    background: black;
    padding: 0.4em 0.5em;
    border-radius: 0.2em;
    // text-transform: uppercase;
    h1 {
      font-weight: 900;
      font-family: $deco;
    }
  }
}

.pages-nav {
  font-size: 0.9em;
  max-width: $max-site-width;
  margin: 0 auto;

  font-family: $sigFont;
  font-style: italic;
  background: $white;
  text-transform: lowercase;
  font-weight: 400;
  padding-left: 1em;
  padding-top: 0.2em;
  display: flex;
  flex-wrap: warp;
  align-items: center;
  .react-toggle {
    margin-right: 0.5em;
  }
  ul {
    padding: 0.2em 0;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: stretch;
  }
  @media screen and (max-width: 480px) {
    font-size: 4vw;
  }
}
a.page-link {
  text-decoration: none;
  color: black;
  display: block;
  font-weight: 600;
  // font-size: 0.8em;
  line-height: 0.7em;
  // letter-spacing: -0.09em;
  // padding: $paddingY 0.3em;
  padding: $paddingY 0.2em calc(#{$paddingY} - #{$borderWidth}) 0.2em;
  // margin: 0.4em 0.05em;
  // padding: 0.2em 0.2em;
  // box-sizing: border-box;
  border-bottom: $borderWidth solid $white;

  &:hover, &.active {
    color: black;
    border-bottom: $borderWidth solid $red;

  }
}
a.order-online-button {
  // margin: 2em 0;
  font-size: 0.5em;
  display: inline-block;
  color: $white;
  text-decoration: none;
  padding: 0.3em 0.5em;
  background: $red;
  font-family: $sigFontCompressed;
  font-weight: 600;
  box-shadow: none;
  // margin-right: 0.5em;
  // margin: 0.5em;
  // margin-left: 0;
  border-radius: 15px;
}
a.order-from-old {
  font-size: 0.4em;
  display: inline-block;
  color: white;
  text-decoration: none;
  padding: 0.3em 0.5em;
  background: black;
  font-family: $sigFontCompressed;
  font-weight: 600;
  box-shadow: none;
  // margin-right: 0.5em;
  // margin: 0.5em;
  // margin-left: 0;
  border-radius: 15px;
}

.full-menu-link-wrapper {
  padding: 1em 0;
  text-align: center;
  a.button {
    background: $red;
    color: white;
  }
}
  .menu-item {
    header {
      // font-family: $sigFont;
      // font-size: 1.2em;
    }
  }

  .footer {
    background: black;
  }

}

.page-title {

  font-family: $deco;
  font-weight: 600;
  // font-size: 2em;
  padding: 1em 0;
  text-align: center;
}

.photos {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 800px;
  margin: 0 auto;
  .photo-wrapper {

    width: 45%;
    img {
      border: 4px solid black;
      width: 100%;
    }
  }
}


.menu-category-list-view {
  .mag-glass-svg {
    display: none;

  }
}


.page-title {
  text-align: left;
  font-size: 1.5em;
  font-weight: 900;
  font-family: $deco;
  text-transform: uppercase;
  margin-top: 1em;
}
.contact-page {
  .top-banner {
    margin: 1em 0;
    width: 100%;
  }
  max-width: 650px;
  margin: 0 auto;
  margin-bottom: 2em;
  padding: 0 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5em;
  strong {
    font-weight: 600;
  }
  h2 {
    font-weight: 900;
    font-family: $deco;
    text-transform: uppercase;
    margin: 1em 0;
  }
  ul {
    list-style: disc;
    padding-left: 1em;
  }
  li, p {
    margin: 0.5em 0;
  }
}
